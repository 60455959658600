/** @format */

export default {
  data() {
    return {};
  },
  computed: {},
  methods: {
    async getStatuses(filters) {
      let statuses = [];

      let s = this.getFromConfig('config.statuses.leads');
      s.forEach((el) => {
        if (el.type === 0 || el.type === undefined) statuses.push(el);
      });
      let params = { filters };
      let resp = await this.$axios.get('mechti/leads_status', { params });
      if (resp.data.status == 'ok') {
        for (let i = 0; i < resp.data.data.length; i++) {
          const el = resp.data.data[i];
          el['value'] = el.id;
          el['text'] = el.name;
          el['type'] = el.leads_status_group_id;
          el['action'] = el.action_text;
          el['next'] = el.data?.next || [];
          el['mask'] = el.data?.mask || '';

          statuses.push(el);
        }
      }
      return [...statuses];
    },
    async getTasks(filters = {}) {
      let res = [];
      let params = { filters };
      let resp = await this.$axios.get('mechti/leads_task_template', { params });
      if (resp.data.status == 'ok') {
        for (let i = 0; i < resp.data.data.length; i++) {
          const el = resp.data.data[i];
          el['value'] = el.id;
          el['text'] = el.name;
          el['action_text'] = el?.data?.action_text || el.name;

          res.push(el);
        }
      }
      return [...res];
    },
  },
};
